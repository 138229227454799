export default {
    zh:{
        xyz:{
            queryParam:{
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                code:'信用证号',
                code_placeholder:'请输入信用证号',
            },
            columns:{
                F_CODE:'信用证号',
                F_BZ:'币种',
                F_AMOUNT:'信用证金额',
                F_KZRQ:'开证日期',
                F_YXQ:'有效期',
                F_ZCZQ:'最迟装期',
                F_ZCJDR:'最迟交单日',
                F_CUSTOMER_ID:'客户',
                F_BANK:'交单行',
            },
            form:{
                code:'信用证号',
                code_placeholder:'请输入信用证号',
                code_rule: '请输入信用证号',
                bz:'币种',
                bz_placeholder:'请选择币种',
                bz_rule: '请选择币种',
                kzrq:'开证日期',
                kzrq_placeholder:'请选择开证日期',
                kzrq_rule: '请选择开证日期',
                yxq:'有效期',
                yxq_placeholder:'请选择有效期',
                yxq_rule: '请选择有效期',
                zczq:'最迟装期',
                zczq_placeholder:'请选择最迟装期',
                zczq_rule: '请选择最迟装期',
                zcjdr:'最迟交单日',
                zcjdr_placeholder:'请选择最迟交单日',
                zcjdr_rule: '请选择最迟交单日',
                bank:'交单行',
                bank_placeholder:'请选择交单行',
                bank_rule: '请选择交单行',
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                customerId_rule: '请选择客户',
                amount:'信用证金额',
                amount_placeholder:'请输入信用证金额',
                amount_rule: '请输入信用证金额',
            },
            XyzDetail:{
                F_PAY_TYPE:'付款类型',
                F_PAY_TYPE_PLACEHOLDER:'请选择付款类型',
                F_ORDER_ID:'订单ID',
                F_ORDER_ID_PLACEHOLDER:'请输入订单ID',
            },
        }
    },
    en:{
        xyz:{
            queryParam:{
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                code:'信用证号',
                code_placeholder:'请输入信用证号',
            },
            columns:{
                F_CODE:'信用证号',
                F_BZ:'币种',
                F_AMOUNT:'信用证金额',
                F_KZRQ:'开证日期',
                F_YXQ:'有效期',
                F_ZCZQ:'最迟装期',
                F_ZCJDR:'最迟交单日',
                F_CUSTOMER_ID:'客户',
                F_BANK:'交单行',
            },
            form:{
                code:'信用证号',
                code_placeholder:'请输入信用证号',
                code_rule: '请输入信用证号',
                bz:'币种',
                bz_placeholder:'请选择币种',
                bz_rule: '请选择币种',
                kzrq:'开证日期',
                kzrq_placeholder:'请选择开证日期',
                kzrq_rule: '请选择开证日期',
                yxq:'有效期',
                yxq_placeholder:'请选择有效期',
                yxq_rule: '请选择有效期',
                zczq:'最迟装期',
                zczq_placeholder:'请选择最迟装期',
                zczq_rule: '请选择最迟装期',
                zcjdr:'最迟交单日',
                zcjdr_placeholder:'请选择最迟交单日',
                zcjdr_rule: '请选择最迟交单日',
                bank:'交单行',
                bank_placeholder:'请选择交单行',
                bank_rule: '请选择交单行',
                customerId:'客户',
                customerId_placeholder:'请选择客户',
                customerId_rule: '请选择客户',
                amount:'信用证金额',
                amount_placeholder:'请输入信用证金额',
                amount_rule: '请输入信用证金额',
            },
            XyzDetail:{
                F_PAY_TYPE:'付款类型',
                F_PAY_TYPE_PLACEHOLDER:'请选择付款类型',
                F_ORDER_ID:'订单ID',
                F_ORDER_ID_PLACEHOLDER:'请输入订单ID',
            },
        }
    }
}